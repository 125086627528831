// Import all of Bootstrap's JS
// import * as bootstrap from 'bootstrap'

// import { Button, Collapse, Dropdown, Modal } from 'bootstrap'

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

document.addEventListener("DOMContentLoaded", function(event) { 

  // aside navbar sticky
  const stickyElement = document.querySelector(".navbar-site")
  const observer = new IntersectionObserver( 
    ([e]) => e.target.classList.toggle("is-sticky", e.intersectionRatio < 1),
    { threshold: [1] }
  );
  observer.observe(stickyElement);

  // collapse text for streamfield block with truncated text
  const truncateRevealer = document.querySelectorAll('.toggle-truncate');
  
  truncateRevealer.forEach(revealItem => {
    revealItem.addEventListener('click', (event) => {
      // console.log(event);
      parentElem = event.target.closest(".block-truncated")
      // console.log("parentElem: ", parentElem)
      let classes = parentElem.classList;
      let toggleResult = classes.toggle("block-truncated-revealed");
      // event.target.textContent = `${toggleResult ? "↗ Weniger lesen" : "↘ Weiterlesen"}`;
    });
   });

});
